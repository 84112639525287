<template>
  <b-row>
    <b-col
      cols="12"
      xl="3"
      md="12"
    >
      <b-card
        text-variant="center"
        class="card card-congratulations"
      >
        <!-- images -->
        <b-img
          :src="require('@/assets/images/elements/decore-left.png')"
          class="congratulations-img-left"
        />

        <b-avatar
          variant="primary"
          size="70"
          class="shadow mb-2"
        >
          <feather-icon
            size="28"
            icon="AwardIcon"
          />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">
          {{ getUserPoints }}
        </h1>
        <b-card-text class="m-auto w-75">
          Stacks de puntos disponibles <br>
          <div
            v-for="(item) in getUserPointsDetails"
            :key="item.id"
          >
            <small><strong>Total de puntos: </strong><span>{{ item.amount }}</span></small> <br>
            <small><strong>Puntos disponibles: </strong><span>{{ item.pointsLeft }}</span></small> <br>
            <small><strong>Vigencia: </strong><span>{{ item.expiration_date }}</span></small> <br>
          </div>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      xl="9"
      md="12"
    >
      <b-card
        no-body
        class="titlenp"
      >
        <h3 class="m-2">
          Historial de Puntos
        </h3>
        <b-table
          responsive="sm"
          :items="getOrdersWithPoints"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BCard, BAvatar, BImg, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BCardText,
  },
  computed: {
    getUserPoints() {
      if (!this.$store.state.account.user.points) {
        return 0
      }
      return this.$store.state.account.user.points
    },
    getUserPointsDetails() {
      if (!this.$store.state.account.user.user_points_details) {
        return []
      }
      return this.$store.state.account.user.user_points_details
    },
    getOrdersWithPoints() {
      if (!store.state.orders.orders.data) {
        return []
      }
      return store.state.orders.orders.data.map(item => ({
        fecha: this.formatDate(item.created_at),
        numero_de_orden: item.number,
        puntos_usados: `$${item.total}`,
      }))
    },
  },
  beforeMount() {
    store.dispatch('orders/indexWithPoints', { currentPage: 1 })
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/Mexico_City',
      }
      const parsedDate = new Date(date)
      const formatter = new Intl.DateTimeFormat('es', options)
      return formatter.format(parsedDate)
    },
  },
}
</script>
